import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAvIKQbXActdKJAxnT8Wh3lWisoSarc1jU",
  authDomain: "myapp-bdcb1.firebaseapp.com",
  databaseURL: "https://myapp-bdcb1-default-rtdb.firebaseio.com",
  projectId: "myapp-bdcb1",
  storageBucket: "myapp-bdcb1.appspot.com",
  messagingSenderId: "463786656130",
  appId: "1:463786656130:web:4a78558ec059a56abd8f9b",
  measurementId: "G-E0ELQGBY2J"
};

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const authentication = getAuth(app)
export const storage = getStorage(app)
